.App {
  text-align: center;
  max-width: 500px;
  margin: 0 auto
}

.bauhaus {
  font-family: Bauhaus;
}

.bauhaus-27 {
  font-family: Bauhaus;
  font-size: 27px;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.font-weight-500 {
   font-weight: 500;
}

.montserrat-14 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.montserrat-16 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.montserrat-12 {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.montserrat-15 {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.orange-bubble-nav {
  background-color: #fe5000;
  width: 42px;
  height: 42px;
  /* transform: translateY(-20%); */
  padding: 6px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#landing-page {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-image: url('./assets/Ellipse 2.png');
  background-repeat: no-repeat;
  background-position: bottom;
}

#pointShop-page {
  overflow-y: hidden;
}

#bottom-bar {
  max-width: 500px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
  height: 8vh;
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
  align-items: flex-end;
  background-color: white;
  z-index: 1000;
  object-fit: contain;
  color: #febc9e;  
  padding-bottom: 10px;
}

#loading-container {
  background-image: url('./assets/Landing\ -\ 1.png');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-container {
  padding: 30% 10px;
  position: relative;
}

.left-triangle {
  width: 16px;
  height: 16px;
  background-color: #fe5000;
  clip-path: polygon(100% 0, 0 51%, 100% 100%);
  position: fixed;
  top: 40%;
}

.right-triangle {
  width: 16px;
  height: 16px;
  background-color: #fe5000;
  clip-path: polygon(0 0, 100% 46%, 0 100%);
  position: fixed;
  top: 40%;
  right: 3%;
}

.left-triangle-hll {
  width: 16px;
  height: 16px;
  background-color: #ffcf32;
  clip-path: polygon(100% 0, 0 51%, 100% 100%);
  position: absolute;
  top: 52%;
  left: 10%;
}

.right-triangle-hll {
  width: 16px;
  height: 16px;
  background-color: #ffcf32;
  clip-path: polygon(0 0, 100% 46%, 0 100%);
  position: absolute;
  top: 52%;
  right: 10%;
}

.landing-btn {
  height: 40px;
  font-size: 17px;
  margin-top: 30px;
  width: 90%;
  background-color: #fe5000;
  color: white;
  font-weight: 600;
}

.border-btn {
  height: 40px;
  font-size: 17px;
  margin-top: 30px;
  width: 90%;
  border: 2px solid #fe5000;
  background-color: transparent;
  color: #fe5000;
  font-weight: 600;
}

.landing-btn:disabled {
  background-color: #9c9c9c;
  color: white;
}

.cart-btn:disabled {
  color: #FE5000 !important;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
}

.home-content {
  background: url('./assets/Rectangle 22699.png');
  height: fit-content;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 23%;
  max-width: 500px;
}

.details-card {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  background: white;
  transform: translateY(-10%);
  border-radius: 20px;
}

.features-container {
  cursor: pointer;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: fit-content;
  height: 72px;
  width: 72px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.line {
  margin: 0 auto;
  border-right: 2px solid black;
  height: 28px;
  align-self: center;
}

.grey-box {
  border-top: 4px solid #eeeeee;
  border-bottom: 4px solid #eeeeee;
  width: 100%;
  margin-top: 30px;
  /* position: fixed; */
  /* left: 0; */
  /* top: 60%; */
}

.status-container {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 10px 5px;
  justify-content: center;
  margin: 15px 0;
  font-size: 14px;
  border-radius: 8px;
  width: 120px;
  white-space: nowrap;
}

.x-scrolling {
  overflow-x: scroll;
}

.reward {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 38vw;
  height: 200px;
  padding: 8px;
  max-height: 220px;
}

.img-container {
  width: 100%;
  max-width: 100%;
  height: 55%;
  overflow: hidden;
  display: grid;
  place-items: center;
}

.plus-btn {
  display: flex;
  align-items: self-end;
  background-color: #fe5000;
  color: white;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.plus-btn.small {
  width: 18px;
  height: 18px;
  align-items: center;
}

.upload-receipt-input-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background:#f66522;
  color: white;
  border-radius: 10px;
  height: 50vh;
  margin: 30px auto;
  width: 100%;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13);
  position: relative;
}

.receipt-input {
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 14px;
}

.carousel-container {
  /* background-image: url('./assets/Group\ 2.png'); */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  height: 490px;
  width: 100%;
  /* background-position: center; */
}

.point-tab {
  font-size: 18px;
  border-radius: 12px;
  width: 50%;
  text-align: center;
  padding: 15px 20px;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}

.point-tab.selected-tab {
background-color: #fff3ed;
}

.point-rewards {
  /* position: relative; */
  transform: translateY(-1%);
  background: #fff3ed;
  /* background-color: #fff3ed; */
  /* height: fit-content; */
  display: flex;
  padding-bottom: 50px;
}

.point-rewards > div > ul {
  border-right: 1px solid #f66522;
  height: 100%;
}

.total-points-display {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 8%;
  padding: 10px 15px 15px;
  background-color: white;
  width: 100%;
}

.filter-active {
  background-color: #fe5000;
  color: white;
  border-radius: 20px;
  padding: 2px 0;
}

.add-to-cart-container {
  background-color: #fe5000;
  height: 175px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.pickup-container {
    background-color: white !important;
    border-radius: 10px;
    padding-left: 16px;
    height: 10vh;
    padding-top: 10px;
  }

.change-outlet-btn {
    width: 45%;
    background-color:black !important;
    padding: 10px 16px;
    border-radius: 20px 0px 10px 0px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: white;
}

.adjust-quantity-btn {
  border-radius: 50px;
  width: 16px;
  height: 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-checkbox-input {
  background-color: aliceblue;
  
}

.add-to-cart-width {
    width: 80%;
}

.reward-card {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.copied {
  position: fixed;
  top: 50%;
  right: 20%;
  background-color: rgba(18, 16, 16, 0.85);
  /* opacity: .7; */
  width: 55%;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
}

.delivery-details-font {
    /* font-family: Hero New; */
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
}

.max-address-font {
    /* font-family: Hero New; */
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    position:relative;
    top: 12px;
    color: #454545;
}

.address-card {
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);
    background-color: white !important;
    border-radius: 10px;
    padding-left: 16px;
    position: relative;
    padding-top: 10px
  }

.edit-address-btn {
  margin-left: auto;
    width: 55%;
    font-size: 14px;
    background-color: #fff3ed !important;
    height: 4vh;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 15px;
    /* position: absolute; */
    /* right: 0; */
    /* bottom: 0; */
    color: #f66522;
}

.delete-address-btn {
    width: 26px;
    background-color: #fff3ed !important;
    height: 26px;
    border-radius: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #f66522;
}

.help-btn.inactive {
  font-size: 14px;
  background: #fff3ed;
  color: #feb695;
  border-radius: 16px;
  padding: 5px 15px;
}

.help-btn.active {
  font-size: 14px;
  background: #fe5000;
  color: white;
  border-radius: 16px;
  padding: 5px 15px;

}

.pref-check .ant-checkbox-inner{
  border-radius: none;
  background-color: #9c9c9c;
}

.buttonContainer {
  transform: translateY(170%);
}


.scrollBackground {
  background-image: url("../src/assets/ScrollOnly.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 20px;
}

.smallScrollBackground {
  background-image: url("../src/assets/SmallScrollOnly.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.test-carousel .ant-carousel .slick-list .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.box-style-3 {
  padding: 5%;
  width: 100%;
  margin: 10px 0;
  box-shadow: 0px 1px 16px #00000014, inset 1px 1px 4px #ffffff40, inset -2px -2px 2px #00455c14;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.sales-container {
  margin-top: 20px;
}

.date {
  margin-top: 10px;
  color: #9C9C9C;
}

.invoice-number {
  font-size: 15px;
  text-align: left;
}

.amount {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold;
  color: #FE5000;
  text-align: left;
}

.attendance-status-processing-color {
  border-radius: 10px;
  background-color: #A4A4A4;
  color: white;
  margin-top: 10px;
  padding: 8px;
}

.attendance-status-approved-color {
  border-radius: 10px;
  background-color: #35BF54;
  color: white;
  margin-top: 10px;
  padding: 8px;
}

.attendance-status-rejected-color {
  border-radius: 10px;
  background-color: #C4113C;
  color: white;
  margin-top: 10px;
  padding: 8px;
}

.approved-bar {
  width: 25%;
  margin-right: 10px;
  border-radius: 20px;
  height: 15px;
  background-color: #feb695;
}

.approved-bar.highlight-bar {
  background-color: #FE5000;
}

.top-blob {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom-blob {
  position: absolute;
  bottom: 0;
  left: 0;
}

.fixed-whatsapp {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 0;
}

.orange-bubble {
  position: absolute;
  top: -15%;
  right: -20%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fe5000;
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orange-overlay {
  background-color: #fff3ed;
  border-bottom-left-radius:  30px;
  border-bottom-right-radius: 30px;
  padding-bottom: 35px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.refer-link-container {
  background-color: white;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 12px;
  margin: 10px 0;
  border: 2px dashed #FE5000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* ANTD STYLING */

.ant-slider {
  cursor: default !important;
}

.ant-slider.ant-slider-disabled .ant-slider-handle::after {
  background-image: url(./assets/icons/Layer_1\(3\).png);
  background-size: contain;
  background-position: top;
  background-color: #fe5000;
  box-shadow: 0 0 0 1px #fe5000;
  width: 18px;
  height: 18px;
  top: -50%;
  cursor: default;
}

.ant-tooltip-open {
  display: none;
}

.ant-input:disabled {
  color: rgba(0, 0, 0, 0.88);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fe5000;
  border-color: #fe5000;
}

.ant-modal-content {
  background-color: #fff3ed !important;
}

.sample-modal > .ant-modal-content {
  background-color: white !important;
}

.ant-slider.ant-slider-disabled .ant-slider-track {
  background-color: #fe5000 !important;
}

.ant-slider.ant-slider-disabled .ant-slider-rail {
  background-color: #feb695 !important;
}

.ant-select-selector {
  font-size: 12px !important;
  border-radius: 15px !important;
  border: 1px solid #feb695 !important;
}

.ant-select-arrow {
  color: #feb695 !important;
}

.slick-dots li.slick-active {
  width: 16px !important;
}

.slick-dots > li > button {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
  background: #fff3ed !important;
  opacity: 1 !important
}

.slick-dots li.slick-active button {
  background: #fe5000 !important;
}

.ant-picker {
  width: 100%;
  border: 1px solid black;
  padding: 8px ;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0;
}

.ant-collapse-item.collapse-line {
  padding-bottom: 20px;
}

.ant-tabs-nav-wrap {
  display: block !important;
}

.ant-tabs-nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ant-tabs-tab {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #c3c3c3 !important;
  width: 50%;
  justify-content: center;
}

.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: black !important;
}

 .ant-tabs-ink-bar {
  height: 1px !important;
  background-color: black !important;
 }

 .ant-tabs .ant-tabs-content {
  position: static;
 }

 .ant-tabs .ant-tabs-content-holder {
  position: relative;
 }

 .placeholder{
  color: #7e7e7e;
  position: relative;
  top: -35px;
  left: -27%;
 }

 .placeholder-date{
  color: #7e7e7e;
  position: relative;
  top: -35px;
  left: -23%;
 }

 .ant-form-item-control-input-content {
  height: 45px;
 }
 
.placeholder:after, .placeholder-date::after{
  content: '*';
  color: red
}

.storeId-input:focus + .placeholder { 
  display: none;
}

.datebox {
  max-width: 60px;
}

.phonenumber.ant-input[disabled] {
  background-color: transparent;
  border: 1px solid black;
}

.phonenumber::placeholder {
  color: rgba(0, 0, 0, 0.88);
}

.last-referral::before {
  content: "";
  width: 20px;
  height: 100%;
  border: 10px solid white;
  position: absolute;
  left: -3px;
}

.white-blank-slate {
  width: 100%;
  background-color: white;
  position: absolute;
  height: 100%;
  z-index: 1;
  bottom: 0;
}

.non-white-blank-slate {
  position: relative;
  z-index: 2;
}

@media screen and (max-height: 780px) {
  .bottom-blob {
    bottom: -11%;
  }
  .top-blob {
    top: -5%
  }
}

@media screen and (max-width: 400px) {
.help-btn.inactive {
  font-size: 14px;
  background: #fff3ed;
  color: #feb695;
  border-radius: 16px;
  padding: 5px 8px;
}

.help-btn.active {
  font-size: 14px;
  background: #fe5000;
  color: white;
  border-radius: 16px;
  padding: 5px 8px;

}
}